import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { BodyTextBlock } from '@/features/common/'
import { LinksList } from '@/features/common/'
import { MarkdownHeading } from '@/features/common/'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?:
    | Queries.HighlightMultimediaSectionFragment
    | Queries.HighlightSectionFragment
    | null
  textAlignment: 'LEFT' | 'RIGHT'
  color?: 'WHITE' | 'GRAY'
  specialBgColor?: 'WHITE' | 'DARK' | 'RED'
}

export const HighlightSectionText = ({
  data,
  textAlignment,
  color,
  specialBgColor,
  ...props
}: Props): JSX.Element => {
  const getSpecialColorHex = () => {
    switch (specialBgColor) {
      case 'DARK':
        return colors.gray20
      case 'RED':
        return colors.red
      case 'WHITE':
        return '#fff'
    }
  }
  const specialBgColorHex = getSpecialColorHex() as string
  const styles = {
    content: css`
      display: flex;
      justify-content: center;
      align-items: center;
      order: ${textAlignment === 'LEFT' && 1};
      background: ${color === 'WHITE'
        ? colors.gray95
        : color === 'GRAY' && '#fff'};
      padding: var(--margin);
      ${specialBgColor &&
      css`
        background-color: ${specialBgColorHex};
        ${specialBgColor === 'WHITE' &&
        css`
          padding: calc(var(--margin) + var(--row-72)) var(--margin);
        `}
      `}
      > div {
        max-width: 48ch;
      }
      ${mq().ms} {
        order: 2;
        > div {
          max-width: none;
        }
        ${specialBgColor &&
        css`
          transform: translateY(calc(-1 * var(--row-36)));
          padding: var(--margin);
          margin-bottom: var(--row-36);
        `}
      }
    `,
    heading: css`
      font-size: var(--fs-60);
      color: ${colors.gray10};
      border-bottom: 2px solid ${colors.red};
      margin: 0 0 0.5em;
      padding-bottom: 0.5em;
      ${specialBgColor &&
      css`
        font-size: var(--fs-60);
        ${specialBgColor === 'DARK' &&
        css`
          border-color: ${colors.redLight};
        `}
        ${specialBgColor === 'RED' &&
        css`
          margin-top: var(--row-36);
          border-color: ${colors.redDark};
          ${mq().ms} {
            margin-top: 0;
          }
        `}
        ${specialBgColor !== 'WHITE' &&
        css`
          color: #fff;
        `}
      `}
    `,
    body: css`
      margin-bottom: 1em;
      ${specialBgColor &&
      css`
        line-height: 1.75;
        ${specialBgColor !== 'WHITE' &&
        css`
          color: #fff;
        `}
      `}
      a {
        ${specialBgColor &&
        css`
          ${specialBgColor === 'DARK' &&
          css`
            color: #fff;
            @media (hover: hover) {
              &:hover {
                color: ${colors.redLight};
              }
            }
          `}
          ${specialBgColor === 'RED' &&
          css`
            color: #fff;
            @media (hover: hover) {
              &:hover {
                color: #000;
              }
            }
          `}
        `}
      }
    `,
  }

  return (
    <div
      css={styles.content}
      {...props}
    >
      <div>
        <MarkdownHeading
          wrapper={'h2'}
          css={styles.heading}
        >
          {data?.heading}
        </MarkdownHeading>
        <BodyTextBlock
          data={data?.body}
          css={styles.body}
        />
        <LinksList data={data?.linksList} />
      </div>
    </div>
  )
}
